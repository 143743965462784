const state = {
  openKeys: localStorage.getItem('openKeys') || '',
  pathTem: localStorage.getItem('pathTem') || '',
  showSite: true,
  showSiteUpload: true,
  showSiteText: true,
  showSiteUploadText: true
}
const mutations = {
  setActiveIndex(state, value) {
    state.openKeys = value
  },
  setPathTem(state, value) {
    state.pathTem = value
  },
  setShowSite(state, value) {
    state.showSite = value
  },
  setShowSiteUpload(state, value) {
    state.showSiteUpload = value
  },
  setShowSiteText(state, value) {
    state.showSiteText = value
  },
  setShowSiteUploadText(state, value) {
    state.showSiteUploadText = value
  },
}
const actions = {
  actionSetActiveIndex({ commit }, value) {
    commit('setActiveIndex', value)
  },
  actionSetPathTem({ commit }, value) {
    console.log(value);
    commit('setPathTem', value)
  },
  actionSetShowSite({ commit }, value) {
    commit('setShowSite', value)
  },
  actionSetShowSiteUpload({ commit }, value) {
    commit('setShowSiteUpload', value)
  },
  actionSetShowSiteText({ commit }, value) {
    commit('setShowSiteText', value)
  },
  actionSetShowSiteUploadText({ commit }, value) {
    commit('setShowSiteUploadText', value)
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}