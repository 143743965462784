import LoginModel from "@/model/login";
import { setLocalStorage } from '@/utils/storage'
import router from "@/router";

const state = {
	sideMenu: [],
	codeLevel1: [],
	codeLevel2: [],
	parentId: []
};

const mutations = {
	setSideMenu(state, value) {
		state.sideMenu = value;
	},
	setCodeLevel1(state, value) {
		state.codeLevel1 = value;
	},
	setCodeLevel2(state, value) {
		state.codeLevel2 = value;
	},
	setParentId(state, value) {
		state.parentId = value;
	},
};

const actions = {
	getMyMenuAuthList({ commit }, value) {
		LoginModel.getMyMenuAuthList({}).then(({ datas }) => {
			console.log(datas);
			if (datas.length) {
				let sideMenu = [];
				let codeLevel1 = [];
				let codeLevel2 = [];
				datas.forEach((item) => {
					switch (item.menuLevel) {
						case 1:
							sideMenu.push(item.authCode);
							break;
						case 2:
							codeLevel1.push(item.authCode);
							break;
						case 3:
							codeLevel2.push(item.authCode);
							break;
					}
				});
				commit("setSideMenu", sideMenu);
				commit("setCodeLevel1", codeLevel1);
				commit("setCodeLevel2", codeLevel2);
				setLocalStorage('codeLevel1', codeLevel1)
				if (sideMenu.includes('NK_WorksManage')) {
					this.dispatch("active/actionSetPathTem", '/contentManagement', { root: true })
					router.push({ name: 'contentManagement' })
				} else if (sideMenu.includes('NK_BaseManage')) {
					this.dispatch("active/actionSetPathTem", '/basicMaterial', { root: true })
					router.push({ name: 'basicMaterial' })
				} else if (sideMenu.includes('NK_Statistics')) {
					this.dispatch("active/actionSetPathTem", '/statistics', { root: true })
					router.push({ name: 'statistics' })
				} else if (sideMenu.includes('NK_BasicSetting')) {
					this.dispatch("active/actionSetPathTem", '/systemLogs', { root: true })
					router.push({ name: 'systemLogs' })
				}
			} else {
				router.push({ path: "/notauth" })
			}
		});
	},
	setParentId({ commit }, value) {
		commit("setParentId", value);
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
};
