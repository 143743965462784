import Vue from "vue";
import VueRouter from "vue-router";
import BlankLayout from "@/components/layout/layOut.vue";

Vue.use(VueRouter);
// 解决vue路由重复导航错误
// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/pages/login/loginView.vue")
  },
  {
    path: "/BlankLayout",
    name: "BlankLayout",
    component: () => import("@/components/layout/layOut.vue")
  },
  {
    path: "/statistics",
    component: BlankLayout,
    children: [
      {
        path: '/statistics',
        name: 'statistics',
        component: () => import("@/pages/statistics/statisticsLog.vue"),
        meta: {
          keepalive: true,
          breadcrumb: ['统计', '']
        }
      },
    ],
  },
  {
    path: "/settings",
    component: BlankLayout,
    children: [
      {
        path: '/settings/systemLogs',
        name: 'systemLogs',
        component: () => import("@/pages/settings/systemLogs.vue"),
        meta: {
          keepalive: true,
          breadcrumb: ['设置', '系统日志']
        }
      },
      {
        path: '/settings/systemNotice',
        name: 'systemNotice',
        component: () => import("@/pages/settings/systemNotice.vue"),
        meta: {
          keepalive: true,
          breadcrumb: ['设置', '系统通知']
        }
      },
      {
        path: '/settings/feedback',
        name: 'feedback',
        component: () => import("@/pages/settings/feedBack.vue"),
        meta: {
          keepalive: true,
          breadcrumb: ['设置', '意见反馈']
        }
      },
      {
        path: '/settings/adminManage',
        name: 'adminManage',
        component: () => import("@/pages/settings/adminManage.vue"),
        meta: {
          keepalive: true,
          breadcrumb: ['设置', '管理员管理']
        }
      },
      {
        path: '/settings/roleManage',
        name: 'roleManage',
        component: () => import("@/pages/settings/roleManage.vue"),
        meta: {
          keepalive: true,
          breadcrumb: ['设置', '角色管理']
        }
      },
      {
        path: '/settings/userManage',
        name: 'userManage',
        component: () => import("@/pages/settings/userManage.vue"),
        meta: {
          keepalive: true,
          breadcrumb: ['设置', '用户管理']
        }
      },
    ],
  },
  {
    path: '/content',
    component: BlankLayout,
    children: [
      {
        path: '/contentManagement',
        name: 'contentManagement',
        component: () => import('@/pages/content/contentManagement.vue'),
        meta: {
          keepalive: true,
          breadcrumb: ['内容管理']
        }
      },
      {
        path: '/contentManagementVideoDetail',
        name: 'contentManagementVideoDetail',
        component: () => import('@/pages/content/contentManagementVideoDetail.vue'),
        meta: {
          keepalive: true,
          breadcrumb: ['内容管理']
        }
      }
    ],
  },
  {
    path: '/basic',
    component: BlankLayout,
    children: [
      {
        path: '/basicMaterial',
        name: 'basicMaterial',
        component: () => import('@/pages/basic/basicMaterial.vue'),
        meta: {
          keepalive: true,
          breadcrumb: ['基础管理', '素材管理']
        }
      },
      {
        path: '/basicMaterialUploadView',
        name: 'basicMaterialUploadView',
        component: () => import('@/pages/basic/basicMaterialUploadView.vue'),
        meta: {
          keepalive: true,
          breadcrumb: ['基础管理', '素材管理']
        }
      },
      {
        path: '/basicClassification',
        name: 'basicClassification',
        component: () => import('@/pages/basic/basicClassification.vue'),
        meta: {
          keepalive: true,
          breadcrumb: ['基础管理', '分类管理']
        }
      },
      {
        path: '/basicClassificationDetail',
        name: 'basicClassificationDetail',
        component: () => import('@/pages/basic/basicClassificationDetail.vue'),
        meta: {
          keepalive: true,
          breadcrumb: ['基础管理', '分类管理']
        }
      },
      {
        path: '/basicLabel',
        name: 'basicLabel',
        component: () => import('@/pages/basic/basicLabel.vue'),
        meta: {
          keepalive: true,
          breadcrumb: ['基础管理', '标签管理']
        }
      },
      {
        path: '/basicHotwords',
        name: 'basicHotwords',
        component: () => import('@/pages/basic/basicHotwords.vue'),
        meta: {
          keepalive: true,
          breadcrumb: ['基础管理', '热词管理']
        }
      },
      {
        path: '/basicPosters',
        name: 'basicPosters',
        component: () => import('@/pages/basic/basicPosters.vue'),
        meta: {
          keepalive: true,
          breadcrumb: ['基础管理', '分类海报管理']
        }
      },
      {
        path: '/uploadView',
        name: 'uploadView',
        component: () => import('@/pages/basic/uploadView.vue'),
        meta: {
          keepalive: true,
          breadcrumb: ['基础管理', '分类海报管理']
        }
      },
    ],
  },
  {
    path: "/",
    redirect: "/login"
  },
  {
    path: '/notauth',
    component: () => import('@/components/NotFound.vue'),
  },
  {
    path: '*',
    component: () => import('@/components/NotFound.vue'),
  },
];

const router = new VueRouter({
  routes,
  mode: "history"
});
router.beforeEach((to, from, next) => {
  const authCodes = localStorage.getItem('rule_codeLevel1')
  switch (to.name) {
    case 'contentManagement':
      if (authCodes.includes("NK_WorksManage")) {
        localStorage.setItem('pathTem', '/contentManagement')
      } else {
        next('/notauth')
      }
      break
    case 'basicMaterial':
      if (!authCodes.includes("NK_BaseManage_MaterialManage")) {
        next('/notauth')
      }
      break
    case 'basicClassification':
      if (!authCodes.includes("NK_BaseManage_CategoryManage")) {
        next('/notauth')
      }
      break
    case 'basicLabel':
      if (!authCodes.includes("NK_BaseManage_TagManage")) {
        next('/notauth')
      }
      break
    case 'basicHotwords':
      if (!authCodes.includes("NK_BaseManage_HotWordManage")) {
        next('/notauth')
      }
      break
    case 'basicPosters':
      if (!authCodes.includes("NK_BaseManage_BannerManege")) {
        next('/notauth')
      }
      break
    case 'statistics':
      if (!authCodes.includes("NK_Statistics")) {
        next('/notauth')
      }
      break
    case 'systemLogs':
      if (!authCodes.includes("NK_BasicSetting_SystemLogManage")) {
        next('/notauth')
      }
      break
    case 'systemNotice':
      if (!authCodes.includes("NK_BasicSetting_SystemMessage")) {
        next('/notauth')
      }
      break
    case 'feedback':
      if (!authCodes.includes("NK_BasicSetting_FeedBack")) {
        next('/notauth')
      }
      break
    case 'adminManage':
      if (!authCodes.includes("NK_BasicSetting_BackendUserManage")) {
        next('/notauth')
      }
      break
    case 'roleManage':
      if (!authCodes.includes("NK_BasicSetting_BackendRoleManage")) {
        next('/notauth')
      }
      break
    case 'userManage':
      if (!authCodes.includes("NK_BasicSetting_AppUserManage")) {
        next('/notauth')
      }
      break
  }

  next()
})
export default router;
