export const debounce = (fun, delay) => {
  let timer = null
  return function (args) {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      fun.call(this, args)
    }, delay)
  }
}
export const throttle = (method, wait, leading = true) => {
  let timeout
  let previous = 0
  return function (...args) {
    const context = this
    const now = new Date().getTime()
    if (!previous && leading === false) previous = now
    const remaining = wait - (now - previous)
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      }
      previous = now
      method.apply(context, args)
    } else if (!timeout) {
      timeout = setTimeout(() => {
        previous = leading === false ? 0 : new Date().getTime()
        timeout = null
        method.apply(context, args)
      }, remaining)
    }
  }
}

export const getQueryString = (name) => {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'iu')
  const r = window.location.search.substr(1).match(reg)
  if (r !== null) return decodeURIComponent(r[2])
  return null
}

export const zeroFill = (i) => {
  if (i >= 0 && i <= 9) return '0' + i
  return i
}

export const getCurrentTime = () => {
  const date = new Date() // 当前时间
  const month = zeroFill(date.getMonth() + 1) // 月
  const day = zeroFill(date.getDate()) // 日
  const hour = zeroFill(date.getHours()) // 时
  const minute = zeroFill(date.getMinutes()) // 分
  const second = zeroFill(date.getSeconds()) // 秒
  const millSecond = zeroFill(date.getMilliseconds()) // 毫秒

  return `${date.getFullYear()}-${month}-${day} ${hour}:${minute}:${second}.${millSecond}`
}

// 过滤查找条件
export const formatFilterMap = (filter) => {
  const temp = {}
  for (const key in filter) {
    if (filter[key] || filter[key] === 0) temp[key] = filter[key]
  }
  return temp
}

// 下载
export const downloadFile = (result, type = 'excel') => {
  let mime = ''
  switch (type) {
    case 'excel':
      mime = 'application/vnd.ms-excel;charset=utf-8'
      break
    case 'pdf':
      mime = 'application/pdf'
      break
    case 'txt':
      mime = 'text/plain'
      break
    default:
  }
  // const url = window.URL.createObjectURL(new Blob([blob],
  //   {type: mime}))
  // const link = document.createElement('a')
  // link.href = url
  // link.setAttribute('download', filename)
  // document.body.appendChild(link)
  // link.click()
  // document.body.removeChild(link)
  const filename = decodeURIComponent(result.headers['content-disposition'].split(';')[1].split("''")[1])
  if (!!window.ActiveXObject || 'ActiveXObject' in window) return window.navigator.msSaveOrOpenBlob(result.data, filename)
  const url = URL.createObjectURL(result.data, { type: mime })
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
}

//  下载
export const downloadWithUrl = (url, type, filename = '附件') => {
  const link = document.createElement('a')
  let _url = url
  let suffixList = []
  let suffix = ''
  switch (type) {
    case 'video':
      suffixList = ['.mp4', '.MP4']
      _url = url.replace(process.env.VUE_APP_VOD_URL, '')
      break
    case 'image':
      suffixList = ['.png', '.PNG', '.jpg', '.JPG', '.jpeg', '.JPEG']
      _url = url.replace(process.env.VUE_APP_STORE, '')
      break
  }

  link.href = _url
  for (let i = 0; i < suffixList.length; i++) {
    if (link.href.search(suffixList[i]) !== -1) {
      suffix = suffixList[i]
      break
    }
  }

  const title = `${filename}@${getCurrentTime()}${suffix}`
  link.setAttribute('download', title)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
