import fetch from '../utils/request.js'

class Model {
  api (options = {}) {
    if (!options.method) options.method = 'get'

    return new Promise((resolve, reject) => {
      let request
      const config = {
        method: options.method,
        url: options.url
      }
      if (options.responseType) config.responseType = options.responseType
      if (options.downs) config.downs = options.downs

      switch (options.method) {
        case 'post':
        case 'put':
          if (options.headers) {
            request = fetch({
              ...config,
              data: options.data
            })
          } else {
            request = fetch({
              ...config,
              data: {
                param: options.data ? options.data : { data: {} }
              }
            })
          }
          break
        default:
          request = fetch({
            ...config,
            params: options.params
          })
      }
      request
        .then(response => {
          if (options.downs) {
            resolve(response)
          } else {
            resolve(response.data)
          }
        }).catch(error => {
          reject(error)
        })
    })
  }

  get (options = {}) {
    options.method = 'get'
    return this.api(options)
  }

  post (options = {}) {
    options.method = 'post'
    return this.api(options)
  }

  put (options = {}) {
    options.method = 'put'
    return this.api(options)
  }

  delete (options = {}) {
    options.method = 'delete'
    return this.api(options)
  }
}

export default Model
