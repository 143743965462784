const menu = [
  {
    name: "内容管理",
    path: "/contentManagement",
    icon: 'snippets',
    authCode: 'NK_WorksManage'
  },
  {
    name: "基础配置",
    path: "/basic",
    icon: 'appstore',
    authCode: 'NK_BaseManage',
    children: [
      {
        name: "素材管理",
        path: "/basicMaterial",
        authCode: 'NK_BaseManage_MaterialManage',

      },
      {
        name: "分类管理",
        path: "/basicClassification",
        authCode: 'NK_BaseManage_CategoryManage',
      },
      {
        name: "标签管理",
        path: "/basicLabel",
        authCode: 'NK_BaseManage_TagManage'
      },
      {
        name: "热词管理",
        path: "/basicHotwords",
        authCode: 'NK_BaseManage_HotWordManage'
      },
      {
        name: "分类海报管理",
        path: "/basicPosters",
        authCode: "NK_BaseManage_BannerManege"
      }
    ]
  },
  {
    name: "统计",
    path: "/statistics",
    icon: 'align-center',
    authCode: 'NK_Statistics'
  },
  {
    name: "设置",
    path: "/settings",
    authCode: 'NK_BasicSetting',
    icon: 'setting',
    children: [
      {
        name: '系统日志',
        path: '/settings/systemLogs',
        authCode: "NK_BasicSetting_SystemLogManage"
      },
      {
        name: '系统通知',
        path: '/settings/systemNotice',
        authCode: "NK_BasicSetting_SystemMessage"
      },
      {
        name: '意见反馈',
        path: '/settings/feedback',
        authCode: "NK_BasicSetting_FeedBack"
      },
      {
        name: '管理员管理',
        path: '/settings/adminManage',
        authCode: "NK_BasicSetting_BackendUserManage"
      },
      {
        name: '角色管理',
        path: '/settings/roleManage',
        authCode: 'NK_BasicSetting_BackendRoleManage',
      },
      {
        name: '用户管理',
        path: '/settings/userManage',
        authCode: "NK_BasicSetting_AppUserManage"
      }
    ],
  },
]

export default menu
