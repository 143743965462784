import Vue from "vue";
import axios from "axios";
import NProgress from "nprogress";
import { getCurrentTime } from "@/utils/util";

import "nprogress/nprogress.css";
const CryptoJS = require('crypto-js')
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 50000,
});

service.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem("rule_token");
    if (token) {
      config.headers.Authorization = token;
    }
    let timestamp = getCurrentTime()
    let sign = CryptoJS.SHA256(`${process.env.VUE_APP_SIGN_KEY}.${timestamp}.${process.env.VUE_APP_SIGN_SECRET}.${JSON.stringify(config.data)}`).toString()
    config.headers = {
      ...config.headers,
      timestamp,
      sign,
    }
    NProgress.start();
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptors
service.interceptors.response.use(
  (response) => {
    console.log(response);
    NProgress.done();
    if (response.data.success || response.request.responseType === "blob") { return response; }
    // errorMsg(res.message || '未知错误')
    Vue.prototype?.$message.error(response.data.message);
    return Promise.reject(response.data.message || "未知错误");
  },
  (error) => {
    console.log(error);
    if (
      error.response &&
      (error.response.status === 401 || error.response.code === "999999998")
    ) {
      // if (cancelFlag) return Promise.reject(error)
      // cancelFlag = true
      // errorMsg('登录过期，请重新登录！')
      window.localStorage.removeItem("rule_token");
      setTimeout(() => {
        window.location.href = `${window.location.origin}/login`;
      }, 1000);
      //   setTimeout(() => {
      //     cancelFlag = false
      //   }, 1000)
    }
    Promise.reject(error);
  }
);

export default service;
