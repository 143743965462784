import Model from "../index";

class Login extends Model {
	// 获取验证码
	getImgVerifyCode(options) {
		options.url = "/api/backend/login/getImgVerifyCode";
		options.responseType = "blob";
		return this.get(options);
	}

	// 登录
	toLogin(options) {
		options.url = "/api/backend/login/toLogin";
		return this.post(options);
	}

	// 退出登录
	toLogout(options) {
		options.url = "/api/backend/login/toLogout";
		return this.post(options);
	}

	// 修改密码
	updatePassword(options) {
		options.url = "/api/backend/login/updatePassword";
		return this.post(options);
	}

	// 获取当前用户授权码
	getMyMenuAuthList(options) {
		options.url = "/api/backend/login/getMyMenuAuthList";
		return this.post(options);
	}
}

const LoginModel = new Login();
export default LoginModel;
