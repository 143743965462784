const mode = 'rule'

const setLocalStorage = (key, value) => {
  try {
    localStorage.setItem(`${mode}_${key}`, value)
  } catch (err) {
    console.error('保存storage出错: ', JSON.parse(err))
  }
}

const getLocalStorage = (key) => {
  try {
    return localStorage.getItem(`${mode}_${key}`)
  } catch (err) {
    console.error('获取storage出错: ', JSON.parse(err))
  }
}

const removeLocalStorageItem = (key) => {
  try {
    localStorage.removeItem(`${mode}_${key}`)
  } catch (err) {
    console.error('移除storage出错: ', JSON.parse(err))
  }
}

export {
  setLocalStorage,
  getLocalStorage,
  removeLocalStorageItem,
}