import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd, { message } from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import "@/style/global.less";
const Message = {};
const messageType = ["success", "error", "warning", "info"];
messageType.forEach((type) => {
  Message[type] = (message, duration = 2000) => message({
      message,
      type,
      duration,
      offset: 20
    });
});
Vue.use(Antd);

Vue.config.productionTip = false;
Vue.prototype.$message = message
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
