<template>
  <div>
    <a-layout class="home_container">
      <a-layout-header class="el-header">
        <myAuth></myAuth>
        <div class="header_left">
          <img src="../../assets/u15.png" alt="" />
        </div>
        <div class="operate">
          <div class="block">你好！ {{ this.userName }}</div>
          <a-button @click="logout"> 退出 </a-button>
          <a-button @click="dialogVisible = true" style="margin-left:15px"> 修改密码 </a-button>
        </div>
      </a-layout-header>
      <a-layout>
        <a-layout-sider width="200" style="background: #fff" class="sidebar-box">
          <a-menu :openKeys="openKeys" mode="inline" @click="handleClick" @openChange="onOpenChange"
            :selectedKeys="[$route.path==='/contentManagementVideoDetail'?'/contentManagement':
            $route.path==='/basicMaterialUploadView'?'/basicMaterial':$route.path==='/uploadView'?'/basicPosters':$route.path]" style="width: 100%">
            <template v-for="item in menuList">
              <a-menu-item v-if="!item.children && sideMenu.includes(item.authCode)" :key="item.path">
                <span>
                  <a-icon :type="item.icon" />{{ item.name }}
                </span>
              </a-menu-item>
              <a-sub-menu v-else-if="item.children && sideMenu.includes(item.authCode)" :key="item.path">
                <span slot="title">
                  <a-icon :type="item.icon" /><span>{{ item.name }}</span>
                </span>
                <template v-for="menuChildren in item.children">
                  <a-menu-item v-if="codeLevel1.includes(menuChildren.authCode)" :index="menuChildren.path"
                    :key="menuChildren.path">
                    {{ menuChildren.name }}
                  </a-menu-item>
                </template>
              </a-sub-menu>
            </template>
          </a-menu>
        </a-layout-sider>
        <a-layout style="padding: 0 24px 24px">
          <BreadCrumb></BreadCrumb>
          <a-layout-content class="el-main">
            <router-view></router-view>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </a-layout>
    <a-modal title="修改密码" :visible="dialogVisible" @cancel="handleCancel" :footer="null">
      <a-form-model :model="user" ref="form" :rules="rules" label-width="120px" :label-col="{ span: 5 }"
        :wrapper-col="{ span: 16 }">
        <a-form-model-item label="输入原密码：" prop="oldPassword">
          <a-input v-model="user.oldPassword" placeholder="请输入原密码" type="password" show-password></a-input>
        </a-form-model-item>
        <a-form-model-item label="输入新密码：" prop="newPassword">
          <a-input v-model="user.newPassword" placeholder="请输入新密码" type="password" show-password></a-input>
        </a-form-model-item>
        <a-form-model-item label="确认新密码：" prop="confirmPassword">
          <a-input v-model="user.confirmPassword" placeholder="请确认新密码" type="password" show-password></a-input>
        </a-form-model-item>
        <div class="export">
          <a-button type='primary' style="margin-left:203px;" @click="save">保存</a-button>
        </div>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
// import myitem from './silder/silDer.vue'
import menu from "../../router/menu";
import myAuth from './auth/Auth.vue'
import BreadCrumb from '../brea/breadCrumb.vue'
import LoginModel from "@/model/login";
import { removeLocalStorageItem } from '@/utils/storage'
import { mapState, mapActions } from 'vuex'

export default {
  data() {
    const equalToPassword = (rule, value, callback) => {
      // eslint-disable-next-line no-negated-condition
      if (this.user.newPassword !== value) {
        callback(new Error('两次输入的密码不一致'))
      } else {
        callback()
      }
    }
    return {
      selectedKeys: this.$route.path,
      openKeys: localStorage.getItem('openKeys') ? [localStorage.getItem('openKeys')] : ['/basic'],
      menuList: [], // 此处为动态获取的菜单数组
      dialogVisible: false,
      user: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      userName: '',
      rules: {
        oldPassword: [{ required: true, message: '旧密码不能为空', trigger: 'blur' }],
        newPassword: [{ required: true, message: '新密码不能为空', trigger: 'blur' }],
        confirmPassword: [
          { required: true, message: '确认密码不能为空', trigger: 'blur' },
          { required: true, validator: equalToPassword, trigger: 'blur' }
        ]
      }
    };
  },
  components: {
    BreadCrumb,
    myAuth,
  },
  created() {
    this.menuList = menu; // 给data里面的菜单数组赋值数据
    this.userName = localStorage.getItem('rule_userName')
  },
  methods: {
    ...mapActions("active", ["actionSetActiveIndex", 'actionSetPathTem']),
    onOpenChange(openKeys) {
      // eslint-disable-next-line no-negated-condition
      if (openKeys.length !== 0) {
        localStorage.setItem('openKeys', openKeys[1])
        this.openKeys = [openKeys[1]]
        this.actionSetActiveIndex(openKeys)
      } else {
        this.openKeys = [""]
      }
    },
    logout() {
      LoginModel.toLogout({ data: {} }).then(data => {
        console.log(data);
        if (data.success) {
          removeLocalStorageItem('token')
          removeLocalStorageItem('userName')
          removeLocalStorageItem('userId')
          removeLocalStorageItem('codeLevel1')
          localStorage.removeItem('pathTem')
          localStorage.removeItem('openKeys')
          localStorage.removeItem('page')
          localStorage.removeItem('dateString')
          localStorage.removeItem('fileNames')
          localStorage.removeItem('conStatus')
          localStorage.removeItem('conPage')
          localStorage.removeItem('conSearchParam')
          localStorage.removeItem('conDateString')
          localStorage.removeItem('conselectDep')
          localStorage.removeItem('categoryId')
          localStorage.removeItem('cataPage')
          this.$message.success('退出成功')
          setTimeout(() => {
            window.location.href = `${window.location.origin}/login`
          }, 1000)
        }
      })
    },

    handleClick(e) {
      console.log(e);
      localStorage.removeItem('typeTem')
      localStorage.removeItem('page')
      localStorage.removeItem('dateString')
      localStorage.removeItem('fileNames')
      localStorage.removeItem('conStatus')
      localStorage.removeItem('conPage')
      localStorage.removeItem('conSearchParam')
      localStorage.removeItem('conDateString')
      localStorage.removeItem('conselectDep')
      localStorage.removeItem('categoryId')
      localStorage.removeItem('cataPage')
      localStorage.removeItem('typeManagement')
      localStorage.setItem('pathTem', e.key)
      this.$router.push({ path: e.key })
      this.actionSetPathTem(e.key)
    },
    handleCancel() {
      this.dialogVisible = false
      this.$refs.form.resetFields()
      this.user = {}
    },
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.dialogVisible = false
          LoginModel.updatePassword({
            data: {
              oldPassword: this.user.oldPassword,
              newPassword: this.user.newPassword,
              confirmPassword: this.user.confirmPassword
            }
          }).then(res => {
            this.$message.success('修改成功')
            this.dialogVisible = false
            // setTimeout(() => {
            //   window.location.href = `${window.location.origin}/login`
            // }, 1000)
          })
        }
      })
    }
  },
  computed: {
    ...mapState('auth', ['sideMenu', 'codeLevel1']),
  }
}
</script>
<style lang="less" scoped>
.el-header {
  background-color: #107d6f;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  height: 60px !important;
}

.header_left {
  width: 200px;
  height: 60px;
  font-weight: bold;
  color: #ffffff;
  line-height: 60px;
  margin-left: -40px;

  .img {
    width: 100%;
  }
}

.sidebar-box {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  min-height: calc(100vh - 60px);
}

.el-main {
  height: calc(100vh - 200px);
  padding: 0;
  box-sizing: border-box;
  background-color: #ffffff;
  overflow: hidden;
  overflow-y: scroll;
}

.home_container {
  background-color: #f5f7fa;
  height: 100vh;
}

.operate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;

  .block {
    margin-right: 30px;
  }
}
</style>
