const state = {
    img: '',
    simpleImg: '',
    imgList: [],
    materialIdList: []
}
const mutations = {
    setImg(state, value) {
        state.img = value
    },
    setSimpleImg(state, value) {
        state.simpleImg = value
    },
    setImgList(state, value) {
        state.imgList = value
    },
    setMaterialIdList(state, value) {
        state.materialIdList.push(value)
    },
    delMaterialIdList(state, value) {
        value === -1
            ? state.materialIdList = []
            : state.materialIdList.splice(value, 1)
    },
}
const actions = {
    setImgList({ commit }, value) {
        commit('setImgList', value)
    },
    setImg({ commit }, value) {
        commit('setImg', value)
    },
    setSimpleImg({ commit }, value) {
        commit('setSimpleImg', value)
    },
    setMaterialIdList({ commit }, value) {
        commit('setMaterialIdList', value)
    },
    delMaterialIdList({ commit }, value) {
        commit('delMaterialIdList', value)
    },
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}