<!-- eslint-disable no-debugger -->
<template>
    <div class="hide">
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    name: 'myAuth',
    created() {
        this.getMyMenuAuthList()
    },
    methods: {
        ...mapActions('auth', ['getMyMenuAuthList']),
    }
}
</script>
<style scoped lang="less">
.hide {
    display: none;
}
</style>