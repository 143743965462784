<template>
  <a-breadcrumb separator=">" style="padding: 16px 10px 10px 20px; font-size: 16px;border-bottom: 1px solid;margin-bottom: 20px;">
    当前位置：<a-breadcrumb-item v-for="(item, index) in $route.meta.breadcrumb" :key="index">{{item}}</a-breadcrumb-item>
  </a-breadcrumb>
</template>

<script>
export default {
  name: 'breadCrumb',
  data () {
    return {

    }
  }
}
</script>

<style scoped>

</style>
